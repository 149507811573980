document.addEventListener('DOMContentLoaded', function () {
    const clickableSpans = document.querySelectorAll('.clickable-span');
    clickableSpans.forEach(span => {
        span.addEventListener('click', function () {
            window.location.href = this.getAttribute('data-href');
        });
    });
});

document.addEventListener('DOMContentLoaded', function () {
    const mailSpans = document.querySelectorAll('.mail-span');
    mailSpans.forEach(span => {
        span.addEventListener('click', function () {
            window.location.href = 'mailto:' + this.innerText;
        });
    });
});