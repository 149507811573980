import Filter from './Filter';

new Filter();

const jobsdetail = document.querySelectorAll('.jobdetail');


if (jobsdetail.length != 0) {
    jobsdetail.forEach(element => {
        const scrollButton = element.querySelector('.scroll-button');
        const form = element.querySelector('#form');

        var navHeight = document.querySelector('nav').offsetHeight;
        var bodyRect = document.body.getBoundingClientRect();
        var itemRect = form.getBoundingClientRect();
        var offset   = itemRect.top - bodyRect.top - navHeight - 50;


        scrollButton.addEventListener('click', function() {
            window.scrollTo({top: offset, behavior: 'smooth'});
        });  
    });
}